import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from './toast.service';
import { TypeDocument, TypeDocumentData, TypeDocumentResponse } from '../models/type-document';

@Injectable({
  providedIn: 'root'
})
export class TypeDocumentService {
  public url = environment.url + 'typeDocument';
  private typeDocuments = new BehaviorSubject<TypeDocument[]>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly allData = this.typeDocuments.asObservable();
  constructor(
    private http: HttpClient,
    private toastService: ToastService
  ) {
    this.getData();
  }

  getData() {
    this.http.get<TypeDocumentResponse>(this.url, {}).subscribe(response => {
      this.set(response.data);
    });
  }

  set(typeDocuments: TypeDocument[]): void {
    this.typeDocuments.next(typeDocuments);
  }
}
