import { Injectable } from '@angular/core';
import { Workshop, WorkshopData, WorkShopDataCollection } from '../../models/configuration/workshop';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '../toast.service';

@Injectable({
  providedIn: 'root'
})
export class WorkshopService {
  public url = environment.url + 'workshop';
  private workshops = new BehaviorSubject<Workshop[]>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly allData = this.workshops.asObservable();
  constructor(
    private http: HttpClient,
    private toastService: ToastService
  ) {
    this.getData();
  }

  getData() {
    this.http.get<WorkShopDataCollection>(this.url, {}).subscribe(response => {
      this.set(response.data);
    });
  }

  saveData(workshop: Workshop) {
    return new Promise<Workshop>(resolve => {
      this.http.post<WorkshopData>(this.url, workshop, {}).subscribe(response => {
        if (response.data.id) {
          this.add(response.data);
          this.toastService.presentToast('Datos grabados con éxito');
          resolve(response.data);
        } else {
          resolve(null);
        }
      });
    });
  }

  async updateData(workshop: Workshop) {
    return new Promise<Workshop>(resolve => {
      this. http.patch<WorkshopData>(this.url + '/' + workshop.id, workshop, {}).subscribe(response => {
        if (response.data.id) {
          this.update(workshop, 'update', workshop);
          this.toastService.presentToast('Datos actualizados correctamente!');
          resolve(response.data);
        } else {
          resolve(null);
        }
      });
    });
  }

  async deleteData(workshop: Workshop) {
    return new Promise<Workshop>(resolve => {
      this.http.delete<Workshop>(this.url + '/' + workshop.id).subscribe(response => {
        if (response.id) {
          this.update(workshop, 'delete');
          this.toastService.presentToast('Datos eliminados con éxito');
          resolve(response);
        } else {
          this.toastService.presentToast('Ocurrió un error!');
          resolve(null);
        }
      });
    });
  }

  getAll(): Workshop[] {
    return this.workshops.getValue();
  }

  getById(id: number): Workshop {
    return this.getAll().find(workshop => workshop.id === id);
  }

  set(workshops: Workshop[]): void {
    this.workshops.next(workshops);
  }

  add(workshop: Workshop): void {
    const workshops = [...this.getAll(), workshop];
    this.set(workshops);
  }

  update(workshop: Workshop, type: string, data: Workshop = null) {
    const findIndex = this.getAll().findIndex(find => find.id === workshop.id);
    const updateData = [...this.getAll()];
    if (type === 'delete') {
      updateData.splice(findIndex, 1);
    } else {
      updateData[findIndex] = data;
    }
    this.workshops.next(updateData);
  }
}
