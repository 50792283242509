import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Proposal, ProposalData, ProposalDataCollection } from '../../models/inspection/proposal';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '../toast.service';

@Injectable({
  providedIn: 'root'
})
export class ProposalService {
  public url = environment.url + 'proposal';
  public proposals = new BehaviorSubject<Proposal[]>([]);
  readonly allData = this.proposals.asObservable();
  constructor(
    private http: HttpClient,
    private toastService: ToastService
  ) {
    this.getData();
  }

  getData() {
    this.http.get<ProposalDataCollection>(this.url, {}).subscribe(response => {
      this.set(response.data);
    }, error => {
      console.log(error);
    });
  }

  set(proposals: Proposal[]) {
    this.proposals.next(proposals);
  }

  sendPackOff(data: any) {
    return new Promise<ProposalData>(resolve => {
      this.http.post<ProposalData>(this.url + '/sendPackOff', data).subscribe(response => {
        resolve(response);
      });
    });
  }

  sendProposal(data: any) {
    return new Promise(resolve => {
      this.http.post(this.url + '/sendProposal', data).subscribe(response => {
        resolve(response);
      });
    });
  }

  replyProposal(data: any) {
    return new Promise(resolve => {
      this.http.post(this.url + '/replyProposal', data).subscribe(response => {
        resolve(response);
      });
    });
  }

  generateOrder(data: any) {
    return new Promise(resolve => {
      this.http.post(this.url + '/generateOrder', data).subscribe(response => {
        resolve(response);
      });
    });
  }

  getAll() {
    return this.proposals.getValue();
  }

  getById(id) {
    return this.getAll().find(proposal => proposal.id === id);
  }
}
