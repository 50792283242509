import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { User } from '../../../models/User';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.page.html',
  styleUrls: ['./search-user.page.scss'],
})
export class SearchUserPage implements OnInit {
  role;
  public searchUser: '';
  public users: User[];
  public backUpUsers: User[];
  constructor(
    private userService: UserService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.userService.allUsers.subscribe(users => {
      this.backUpUsers = users;
      this.users = [...users.filter(user => user.roles[0].name === this.role)];
    });
  }

  dismiss() {
    this.modalController.dismiss({dismissed: true});
  }

  filterUser(evt) {
    this.users = this.backUpUsers.filter(user => user.roles[0].name === this.role);
    const searchItem = evt.srcElement.value;
    if (!searchItem) {
      return;
    }

    this.users = this.backUpUsers.filter(user => {
      if (user.name && searchItem && user.roles[0].name === this.role) {
        return (user.name.toLocaleLowerCase().indexOf(searchItem.toLocaleLowerCase()) > -1);
      }
    });
  }

  assignUser(user: User) {
    this.modalController.dismiss({
      user,
      dismissed: true
    });
  }
}
