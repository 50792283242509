import { Component, OnInit } from '@angular/core';
import { InspectionService } from '../../../services/inspection/inspection.service';
import { Inspection } from '../../../models/Inspection';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.page.html',
  styleUrls: ['./attachments.page.scss'],
})
export class AttachmentsPage implements OnInit {
  public inspection: Inspection;
  public urlS3 = environment.urlS3;
  constructor(
    private inspectionService: InspectionService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.inspectionService.inspectionInProcessData.subscribe(inspection => {
      this.inspection = inspection;
    });
  }

  async assignImage(index) {
    this.modalController.dismiss({
      attachment: {
        urlDocument: this.urlS3 +  this.inspection.photos[index].url
      }
    });
  }
}
