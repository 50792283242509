import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { City } from '../../../models/inspection/city';
import { CityService } from '../../../services/inspection/city.service';

@Component({
  selector: 'app-search-city',
  templateUrl: './search-city.page.html',
  styleUrls: ['./search-city.page.scss'],
})
export class SearchCityPage implements OnInit {
  public cities: City[];
  public backupCities: City[];
  public searchCity: '';
  constructor(
    private modalController: ModalController,
    private cityService: CityService
  ) {
    this.cityService.allData.subscribe(cities => {
      this.backupCities = cities;
      this.cities = [...cities];
    });
  }

  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss({dismissed: true});
  }

  filterCity(evt) {
    this.cities = this.backupCities;
    const searchItem = evt.srcElement.value;
    if (!searchItem) {
      return;
    }

    this.cities = this.backupCities.filter(city => {
      if (city.description && searchItem) {
        return (city.description.toLocaleLowerCase().indexOf(searchItem.toLocaleLowerCase()) > -1);
      }
    });
  }

  assignCity(city: City) {
    this.modalController.dismiss({
      city,
      dismissed: true
    });
  }
}
