import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../models/User';
import { ToastService } from './toast.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public url = environment.url + 'user';
  private users = new BehaviorSubject<User[]>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly allUsers = this.users.asObservable();
  constructor(
    private http: HttpClient,
    private toastService: ToastService
  ) {
    this.getUsersData();
  }

  getUsersData() {
    this.http.get<User[]>(this.url, {}).subscribe(response => {
      this.setUsers(response);
    });
  }

  async updateUser(user: User) {
    return new Promise<User>(resolve => {
      this.http.patch<User>(this.url + '/' + user.id, user, {}).subscribe(response => {
        if (response.id) {
          const findIndex = this.getUsers().findIndex(findUser => findUser.id === user.id);
          const updateUsers = [...this.getUsers()];
          updateUsers[findIndex] = response;
          this.users.next(updateUsers);
          this.toastService.presentToast('Usuario actualizado con éxito.');
          resolve(response);
        } else {
          this.toastService.presentToast('Ocurrió un error!');
        }
      });
    });
  }

  async saveUser(user: User) {
    return new Promise<User>(resolve => {
      this.http.post<User>(this.url, user, {}).subscribe(response => {
        if (response.id) {
          this.addUser(response);
          this.toastService.presentToast('Usuario grabado con éxito.');
          resolve(response);
        } else {
          this.toastService.presentToast('Ocurrió un error!');
          resolve(null);
        }
      });
    });
  }

  async deleteUser(user: User) {
    return new Promise<User>(resolve => {
      this.http.delete<User>(this.url + '/' + user.id).subscribe(response => {
        if (response.id) {
          this.toastService.presentToast('Usuario eliminado con éxto.');
          const findIndex = this.getUsers().findIndex(findUser => findUser.id === user.id);
          const updateUsers = [...this.getUsers()];
          updateUsers.splice(findIndex, 1);
          this.users.next(updateUsers);
          resolve(response);
        } else {
          this.toastService.presentToast('Ocurrió un error');
          resolve(null);
        }
      });
    });
  }

  setUsers(users: User[]): void {
    this.users.next(users);
  }

  getUsers(): User[] {
    return this.users.getValue();
  }

  addUser(user: User): void {
    const users = [...this.getUsers(), user];
    this.setUsers(users);
  }

  getUserById(id: number) {
    return this.getUsers().find(user => user.id === id);
  }
}
