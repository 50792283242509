import { Component, OnInit } from '@angular/core';
import { WorkshopService } from '../../../services/configuration/workshop.service';
import { Workshop } from '../../../models/configuration/workshop';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-search-workshop',
  templateUrl: './search-workshop.page.html',
  styleUrls: ['./search-workshop.page.scss'],
})
export class SearchWorkshopPage implements OnInit {
  public workshops: Workshop[];
  public backupWorkshops: Workshop[];
  public searchWorkshop: '';
  constructor(
    private workshopService: WorkshopService,
    private modalController: ModalController
  ) {
    this.workshopService.allData.subscribe(workshops => {
      this.backupWorkshops = workshops;
      this.workshops = [...workshops];
    });
  }

  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss({dismissed: true});
  }

  filterWorkshop(evt) {
    this.workshops = this.backupWorkshops;
    const searchItem = evt.srcElement.value;
    if (!searchItem) {
      return;
    }

    this.workshops = this.backupWorkshops.filter(workshop => {
      if (workshop.name && searchItem) {
        return (workshop.name.toLocaleLowerCase().indexOf(searchItem.toLocaleLowerCase()) > -1);
      }
    });
  }

  assignWorkshop(workshop: Workshop) {
    this.modalController.dismiss({
      workshop,
      dismissed: true
    });
  }
}
