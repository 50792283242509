import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {Property, PropertyResponse} from '../models/Property';
import {HttpClient} from '@angular/common/http';
import {ToastService} from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  public url = environment.url + 'property';
  private properties = new BehaviorSubject<Property[]>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly allProperties = this.properties.asObservable();
  constructor(
    private http: HttpClient,
    private toastService: ToastService
  ) {
    this.getData();
  }

  getData() {
    this.http.get<PropertyResponse>(this.url, {}).subscribe(response => {
      this.set(response.data);
    });
  }

  saveData(property: Property) {
    return new Promise<Property>(resolve => {
      this.http.post<Property>(this.url, property, {}).subscribe(response => {
        if (response.id) {
          this.add(response);
          this.toastService.presentToast('Propiedad grabada con éxito.');
          resolve(response);
        } else {
          this.toastService.presentToast('Ocurrió un error!');
          resolve(null);
        }
      });
    });
  }

  async updateData(property: Property) {
    return new Promise<Property>(resolve => {
      this.http.patch<Property>(this.url + '/' + property.id, property, {}).subscribe(response => {
        if (response.id) {
          const findIndex = this.get().findIndex(findData => findData.id === property.id);
          const updateData = [...this.get()];
          updateData[findIndex] = response;
          this.properties.next(updateData);
          this.toastService.presentToast('Datos actualizados correctamente.');
          resolve(response);
        } else {
          this.toastService.presentToast('Ocurrió un error!');
        }
      });
    });
  }

  async deleteData(property: Property) {
    return new Promise<Property>(resolve => {
      this.http.delete<Property>(this.url + '/' + property.id).subscribe(response => {
        if (response.id) {
          this.toastService.presentToast('Registro eliminado con éxto.');
          const findIndex = this.get().findIndex(find => find.id === property.id);
          const updateData = [...this.get()];
          updateData.splice(findIndex, 1);
          this.properties.next(updateData);
          resolve(response);
        } else {
          this.toastService.presentToast('Ocurrió un error');
          resolve(null);
        }
      });
    });
  }

  getById(id: number) {
    return this.get().find(property => property.id === id);
  }

  get(): Property[] {
    return this.properties.getValue();
  }

  set(properties: Property[]): void {
    this.properties.next(properties);
  }

  add(property: Property) {
    const properties = [...this.get(), property];
    this.set(properties);
  }
}
