import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Property } from '../../../models/Property';
import { PropertyService } from '../../../services/property.service';

@Component({
  selector: 'app-search-property',
  templateUrl: './search-property.page.html',
  styleUrls: ['./search-property.page.scss'],
})
export class SearchPropertyPage implements OnInit {
  public properties: Property[];
  public backupProperties: Property[];
  public searchProperty: '';
  constructor(
    private modalController: ModalController,
    private propertyService: PropertyService
  ) {
    this.propertyService.allProperties.subscribe(properties => {
      this.backupProperties = properties;
      this.properties = [...properties];
    });
  }

  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss({dismissed: true});
  }

  filterProperty(evt) {
    this.properties = this.backupProperties;
    const searchItem = evt.srcElement.value;
    if (!searchItem) {
      return;
    }

    this.properties = this.backupProperties.filter(property => {
      if (property.name && searchItem) {
        return (property.name.toLocaleLowerCase().indexOf(searchItem.toLocaleLowerCase()) > -1);
      }
    });
  }

  assignProperty(property: Property) {
    this.modalController.dismiss({
      property,
      dismissed: true
    });
  }
}
