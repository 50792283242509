import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  public width: number = 0;
  constructor(
    private plt: Platform
  ) {
    this.width = this.plt.width();
  }

  getWidth() {
    return this.width;
  }
}
