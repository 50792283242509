import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '../toast.service';
import { City, CityDataCollection } from '../../models/inspection/city';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  public url = environment.url + 'city';
  private cities = new BehaviorSubject<City[]>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly allData = this.cities.asObservable();
  constructor(
    private http: HttpClient,
    private toastService: ToastService
  ) {
    this.getData();
  }

  getData() {
    this.http.get<CityDataCollection>(this.url, {}).subscribe(response => {
      this.set(response.data);
    });
  }

  set(typeCars: City[]): void {
    this.cities.next(typeCars);
  }
}
