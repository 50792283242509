import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-type-inspection',
  templateUrl: './type-inspection.page.html',
  styleUrls: ['./type-inspection.page.scss'],
})
export class TypeInspectionPage implements OnInit {
  selected = 0;
  constructor(
    private navController: NavController
  ) { }

  ngOnInit() {
  }

  changeSelect(evt) {
    this.selected = evt.detail.value;
  }

  goToCoordination() {
    this.navController.navigateForward('Inspeccion/Coordinacion', {
      queryParams: {
        selected: this.selected
      }
    });
  }
}
